import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
             
function Clients(props) {

    const clientsInfo = 
      [
        "/IMG_5003.PNG",
        "/IMG_3732.PNG",
        "/mdis-trans.png",
        "/IMG_4094.PNG",
        "/IMG_4096.PNG",
        "/IMG_4099.PNG",
        "/IMG_4100.PNG",
        "/IMG_4102.PNG",
        "/IMG_4173.PNG",
        "/IMG_4174.PNG",
        "/IMG_5007.PNG",
        "/IMG_4179.PNG",
        "/IMG_4180.WEBP",
        "/IMG_5008.PNG",
        "/IMG_4185.PNG",
        "/f86f2e34-2baa-447e-a41b-035c210c6a69.png"
      ];

    const [clients, setClients] = useState(clientsInfo);

    if(!props.data){
      return null;
    }    
    const pageTitle = props.data.title;

    return (
      <section id="clients">
          <div className="row">
            <div>
              <h2>{pageTitle}</h2>
      
              <Slider
              {...{
                slidesToShow: 4,
                slidesToScroll: 2,
                arrows: false,
                dots: true,
                autoplay: true,
                responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ],
              }}
            >
              {clients.map((item, i) => (
                <div className={"contentContainer"} key={i}>
                    <img style={{margin:"auto", maxHeight:240}} src={"/images/client-logos/"+item} alt="Client Logo" />
                </div>
              ))}
              </Slider>
            </div>
          </div>
      </section>
    );

}

export default Clients;